<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="">
                <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
                    <i class="mdi mdi-keyboard-backspace"></i></button>
                Video Content of {{ this.category_name }}
            </h3>
            <div class="btn-group">
                <!-- <button type="button" class="btn btn-info" @click="goBack()">
                    <i class="mdi mdi-keyboard-backspace"></i> Back to Videos listing</button> -->

                <!-- <button type="button" class="btn btn-warning" @click="showEditModal()">
                    <i class="mdi mdi-lead-pencil"></i> Update</button>

                <button type="button" class="btn btn-danger" @click="goBack()">
                    <i class="mdi mdi-delete"></i> Delete</button> -->

            </div>
            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <!-- <b-form @submit.stop.prevent="onSubmit"> -->
                                <div class="row">
                                    <div class="col-md-12">
                                        <video width="60%" height="500" :poster="form.image" controls>
                                            <source :src="form.path" type="video/mp4">
                                            <!-- Your browser does not support the video tag. -->
                                        </video>

                                        <!-- <p>{{ form.path }}</p> -->
                                        <!-- <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group> -->
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <h2>{{ form.name }}</h2>
                                        <p><small><b>Artist : </b> {{ form.artist }}</small></p>
                                        <p><small><b>Date : </b> {{ getFormattedTime(form.date,'ll') }}</small></p>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <u><b>Description :</b></u>
                                        <p>{{ form.description }}</p>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="selected-tags-box">
                                            <div v-if="typeof tags == 'string'">
                                                <u><b>Selected Tags :</b></u>
                                                <div class="tag">
                                                    <span>{{ tags }}</span>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <u><b v-if="tags.length > 0">Selected Tags :</b></u>
                                                <div class="tag" v-for="(tag, index) in tags" :key="'tag' + index">
                                                    <span>{{ tag }}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>






                                <!-- <div class="container-fluid my-4">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="file-box row" @click="$refs.image.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange" ref="image"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload play screen image here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="image_url !== ''" :src="image_url"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                            </div>
                                        </div>
                                    </div> -->

                                <!-- <b-form-group id="example-input-group-1" label="Play Screen Image"
                                        label-for="example-input-1">
                                        <img v-if="image_url == ''" :src="'/assets/base.jpg'"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <img v-if="image_url !== ''" :src="image_url"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                      dateshow : function (value){
        var date=moment(value).fromNow(); // here u modify data 
        this.el.innerText=date; // and set to the view

    }      style="height: 65px; width: 65px" />
                                        <i class="fa fa-camera" @click="$refs.image.click()"
                                            style="font-size: 25px;"></i>
                                        <div class="clear"></div>
                                        <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                        <input class="ml" type="file"  @change="onAudioImageChange"
                                            ref="image" style="display: none" />
                                    </b-form-group>

                                    <b-form-group id="example-input-group-1" label="Cover Image"
                                        label-for="example-input-1">
                                        <img v-if="image_url2 == ''" :src="'/assets/base.jpg'"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <img v-if="image_url2 !== ''" :src="image_url2"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <i class="fa fa-camera" @click="$refs.cover_image.click()"
                                            style="font-size: 25px;"></i>
                                        <div class="clear"></div>
                                        <p class="err" v-if="image_error2 != ''">{{ image_error2 }}</p>
                                        <input class="ml" type="file"  @change="onAudioImageChange2"
                                            ref="cover_image" style="display: none" />
                                    </b-form-group>

                                    <b-form-group id="example-input-group-1" label="Audio" label-for="example-input-1">
                                        <a href="javascript:;" class="btn btn-primary" @click="$refs.audioFile.click()">
                                            <i class="mdi mdi-upload"></i> Upload Audio</a>
                                        <AudioFile :key="count" :audioSource="audioFile" />
                                        <div class="clear"></div>
                                        <input class="ml" type="file" 
                                            @change="uploadAudio" ref="audioFile" style="display: none" />
                                        <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
                                    </b-form-group> -->

                                <!-- <Tag @updateTag="updateTagFromChild" :oldtags="tags" />
                                    <p class="err" v-if="tags_err != ''">{{ tags_err }}</p> -->

                                <!-- <Tag @updateTag="updateTagFromChild" oldtags="" />
                                    <p class="err" v-if="tags_err != ''">{{ tags_err }}</p> -->

                                <!-- <b-form-group id="example-input-group-1" label="Text"
                                        label-for="example-input-1">
                                        <vue-editor v-model="form.text" />
                                    </b-form-group> -->

                                <!-- <b-form-group id="example-input-group-1" label="Description"
                                        label-for="example-input-1">
                                        <b-form-textarea id="example-input-1" name="example-input-1"
                                            v-model="$v.form.description.$model"
                                            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>
                                    </b-form-group> -->

                                <!-- <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button> -->
                                <!-- </b-form> -->
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "../../../../node_modules/vue2-editor";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            category_name : null,
            count: 0,
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            image_error2: "",
            image_url2: "",
            audioFile: "",
            audioFile_err: "",
            tags_err: "",
            file_duration: "",
            tags: [],
            form: {
                id: null,
                text: null,
                name: null,
                artist: null,
                image: "",
                path: null,
                description: null,
                cat_id: null,
                duration: null,
                cover_image: null,
                author: null,
                date: null,
            }
        }
    },

    components: {
        VueEditor,
        Tag,
        AudioFile
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            name: {
                required,
            },
            description: {
                required,
            },
            author: {
                required,
            },
        }
    },
    watch: {
        tags(newVal) {
            if (newVal.length > 0) {
                this.tags_err = "";
            }
        }
    },

    mounted() {
        window.addEventListener("keypress", function (e) {
            if (e.code == "Enter") {
                e.preventDefault()
            }
        }.bind(this));
    },

    created() {
        this.items()
    },

    methods: {

        async showEditModal() {
            this.$store.dispatch("setDataInEditForm", { "prev_url": window.location.pathname });
            const url = `/series/${this.form.id}/edit/${this.cat_id}`;
            this.$router.push({ path: url });
        },

        async items() {
            let data = await this.$store.getters['getEditFormData'];

            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }

            this.category_name = data?.category_name
            this.form.cat_id = data?.category_id;
            this.form.id = data?.id;
            this.form.name = data?.name;
            this.form.artist = data?.artist;
            this.form.description = data?.description;
            this.form.image = data?.image;
            this.form.image_url = data?.image_url;
            this.tags = data?.tag;
            this.form.path = data?.path;
            this.form.date = data?.date;
        },

        closeAudioPlayer() {
            this.audioFile = "";
            this.$refs.audioFile.value = null;
        },

        goBack() {
            let prev_url = `/video/list/${this.form.cat_id}`;
            this.$router.push({ path: prev_url });
        },

        updateTagFromChild(arr) {
            this.tags = arr;
        },
        onAudioImageChange(e) {
            this.form.image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },
        onAudioImageChange2(e) {
            this.form.cover_image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image2')
            if (status) {
                this.image_error2 = "";
                this.form.cover_image = file;
                this.image_url2 = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (num == 'audio') {
                types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
                if (!types.includes(file.type)) {
                    this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
                    this.audioFile = ""
                    return false;
                }
                return true;
            } else {
                if (!types.includes(file.type)) {
                    if (num == 'image1') {
                        this.image_error = msg
                        this.form.image = "";
                    }
                    if (num == 'image2') {
                        this.image_error2 = msg
                        this.form.cover_image = "";
                    }
                    return false;
                }
                return true;
            }
        },

        uploadAudio(e) {
            this.audioFile_err = "";
            this.audioFile = "";
            const audio = e.target.files[0];
            var status = this.checkFileType(audio, 'audio');
            if (status) {
                const reader = new FileReader();
                reader.readAsDataURL(audio);
                var audio_el = document.createElement("audio");
                reader.onload = (e) => {
                    this.audioFile = e.target.result;
                    console.log('audio upload');
                    console.log(this.audioFile);
                    audio_el.src = e.target.result;
                    audio_el.addEventListener("loadedmetadata", () => {
                        var duration = audio_el.duration;
                        // const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
                        this.file_duration = duration;
                        // console.log(
                        //   "The duration of the song is of: " + this.file_duration + " seconds"
                        // );
                    });
                };
                this.count++;
                // console.log(this.file_duration, "Song Duration");
            }
            console.log('audio upload');
            console.log(this.audioFile);
        },

        async onSubmit() {
            console.log('click on submit')
            console.log(this.image_url)
            console.log(this.tags)
            // console.log(this.tags.length);
            // console.log(this.$v.form.description.$error);
            // console.log(this.$v.form.author.$error);
            this.$v.form.$touch()

            if (this.$v.form.$anyError) {
                return false;
            }
            if (this.image_url != ""
                // && this.image_url2 != ""
                // && this.audioFile != ""
                && this.tags.length != 0
            ) {
                try {
                    this.is_loading = true;
                    let obj = this.$store.getters['getEditFormData'];
                    // console.log('localstorage');
                    //     console.log(localStorage.getItem('obj'));
                    // if(!obj){
                    //     obj = localStorage.getItem('obj');                      
                    // }
                    let endpoint = "/admin/update-chapter";

                    // if (obj != "") {
                    //     if (obj.type == "hypnosis") {
                    //         endpoint = "/admin/add-hypnosis";
                    //     }
                    //     else if (obj.type == "meditation") {
                    //         endpoint = "/admin/add-meditation";
                    //     }
                    //     else if (obj.type == "affirmation") {
                    //         endpoint = "/admin/add-affirmation";
                    //     }
                    // }

                    //             formData.append("name", this.vid_name);
                    // formData.append("script_id", this.cat_id);
                    // formData.append("description", this.vid_desc);
                    // formData.append("duration", this.text_duration);
                    // formData.append("author", this.chapter_author);
                    // formData.append("text", this.vid_text);
                    // // formData.append("duration", this.file_duration);
                    // formData.append("image", this.blob);

                    // if(typeof this.tags == 'string'){
                    //   formData.append('tag[]', this.tags);
                    // }else{
                    //   for (var i = 0; i < this.tags.length; i++) {
                    //     formData.append('tag[]', this.tags[i]);
                    //   }
                    // }


                    console.log(obj);
                    const formData = new FormData();
                    formData.append("id", this.form?.id);
                    formData.append("name", this.form?.name);
                    formData.append("script_id", obj.category_id);
                    formData.append("description", this.form.description);
                    // formData.append("duration", this.text_duration);
                    formData.append("author", this.form.author);
                    formData.append("text", this.form.text);

                    if (typeof this.tags == 'string') {
                        formData.append('tag[]', this.tags);
                    } else {
                        for (var i = 0; i < this.tags.length; i++) {
                            formData.append('tag[]', this.tags[i]);
                        }
                    }

                    // if (this.$refs.audioFile.files[0]) {
                    //     formData.append("path", this.$refs.audioFile.files[0]);
                    // }
                    if (this.form.image) {
                        formData.append("image", this.form.image);
                    }
                    // if (this.form.cover_image) {
                    //     formData.append("cover_image", this.form.cover_image);
                    // }

                    let result = await api.post(endpoint, formData);
                    const msg = await result.data.message;
                    // console.log('after requrest');
                    // console.log(result);
                    this.$store.dispatch('setMessage', { msg });
                    console.log(this.$root.$refs);
                    // this.showSnotifySuccess(msg)
                    // console.log('cat id', obj.category_id);
                    await this.$store.dispatch(`${obj.type}/all_after_submit`, {
                        id: obj.category_id
                    });
                    // this.$root.$refs.Audio.$refs['add-modal'].hide();
                    const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
                    let prev_url = `/scripts/list/${category_id}`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                } catch (error) {
                    console.log(error);
                    // this.progress = 0;
                    // let err = error.response.data.message;
                    // this.$store.dispatch('setMessage', { err });
                    // this.showSnotifyError()
                    // this.$toast.error(this.error);
                }
            } else {
                // this.audioFile_err = (this.audioFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
                this.image_error = (this.image_url == "" && this.image_error == "") ? "Image is required" : this.image_error
                // this.image_error2 = (this.image_url2 == "" && this.image_error2 == "") ? "Image is required" : this.image_error2
                this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags required" : this.tags_err
            }
        },


        showSnotifySuccess(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.success(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        showSnotifyError(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.error(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },

    },




}
</script>